import { useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { IProfile } from '../../../interfaces/IViewProfile.interface'
// components
import { Card, Link } from '../../../../common/components'
import { getScope } from '../../../util/util'

type IDetailsProps = {
  entity: IProfile & {
    based_in?: { name: string };
    summary?: string;
  },
  isPublicProfile?: boolean
}

export const ProfileDetails = ({ entity, isPublicProfile }: IDetailsProps) => {
  const [showDescription, setShowDescription] = useState(false)
  const [showMoreDescButton, setShowMoreDescButton] = useState(false)

  const {
    based_in: basedIn,
    display_name: displayName,
    email,
    location,
    description,
    established,
    proposals,
    granteeContacts,
    contact,
    scope,
    summary
  } = entity || {}

  const primaryContact = granteeContacts?.[0]?.contact ?? contact

  const contactCheck = !!primaryContact || !!displayName || !!email

  const readMoreDesc = (string: string, maxWords: number) => {
    const strippedString = string.trim()
    const array = strippedString.split(' ')
    const wordCount = array.length
    let newString = array.splice(0, maxWords).join(' ')

    if (wordCount > maxWords) {
      newString += '...'
      if (!showMoreDescButton) setShowMoreDescButton(true)
    }
    return newString
  }

  return (
    <Card paddingX={50} paddingY={40}>
      <Grid container spacing={{ xs: 4, md: 6, lg: 9 }}>
        <Grid sm={12} md={6} item container spacing={2} display="block">
          {!isPublicProfile && summary && (
            <Grid item container spacing={2} display="block">
              <Grid item xs={12}>
                <Typography variant="h3" fontSize={24} fontWeight={600}>
                  Summary
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }} variant="body2">
                  {summary}
                </Typography>
              </Grid>
            </Grid>
          )}
          {description && (
            <Grid item container spacing={2} display="block">
              <Grid item xs={12}>
                <Typography variant="h3" fontSize={24} fontWeight={600}>
                  {Array.isArray(proposals) && 'Organization'} Description
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }} variant="body2">
                  {description && showDescription ? description : readMoreDesc(description, 100)}
                </Typography>
                {showMoreDescButton && (
                  <Link action={() => setShowDescription(!showDescription)}>
                    <Box component="div" display="flex" alignItems="center">
                      {showDescription ? 'Show less' : 'Show more'}
                      {showDescription ? (
                        <ArrowDropUpIcon sx={{ margin: 0 }} />
                      ) : (
                        <ArrowDropDownIcon />
                      )}
                    </Box>
                  </Link>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid sm={12} md={(!isPublicProfile && summary) || description? 6 : 12} item container spacing={2} display="block">
          <Grid item>
            <Typography
              variant="h3"
              fontSize={24}
              fontWeight={600}
            >
              {!isPublicProfile ? 'Fund Information' : 'Profile Information' }
            </Typography>
          </Grid>
          {!!established && (
            <Grid item xs={12} sm={6} md={12}>
              <Typography variant="h6">Established</Typography>
              <Typography variant="body2">{established}</Typography>
            </Grid>
          )}
          {(scope && isPublicProfile) ? (
            <Grid item xs={12} sm={6} md={12}>
              <Typography variant="h6">Scope</Typography>
              <Typography variant="body2">{getScope(scope)}</Typography>
            </Grid>
          ) : null}
          {(!!location?.state || basedIn?.name) && (
            <Grid item xs={12} sm={6} md={12}>
              <Typography variant="h6">Based In</Typography>
              <Typography variant="body2">{location?.state ?? basedIn?.name}</Typography>
            </Grid>
          )}
          {contactCheck && (
            <Grid item xs={12} sm={6} md={12}>
              <Typography variant="h6">Contact</Typography>
              {!!primaryContact ? (
                <>
                  <Typography variant="body2">
                    { (isPublicProfile && primaryContact.display_name) ? 
                    primaryContact.display_name :
                    `${primaryContact.first_name} ${primaryContact.last_name}`
                    }
                  </Typography>
                  {!!primaryContact.phone && (
                    <Typography variant="body2">{primaryContact.phone}</Typography>
                  )}
                  {!!primaryContact.email && (
                    <Link target="_blank" href={`mailto:${primaryContact.email}`}>
                      {primaryContact.email}
                    </Link>
                  )}
                </>
              ) : (
                <>
                  {!!displayName && <Typography variant="body2">{displayName}</Typography>}
                  {!!email && (
                    <Typography variant="body2">
                      <Link target="_blank" href={`mailto:${email}`}>
                        {email}
                      </Link>
                    </Typography>
                  )}
                </>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Card>
  )
}