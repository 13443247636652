import React, { FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { IRfp } from '../../interfaces/IRfp.interfaces';
import DOMPurify from 'dompurify';
import { format } from 'date-fns-tz';

type props = {
  rfp: IRfp;
};

type KeyDateItemProps = {
  title: string;
  date?: Date;
  isNewDate?: boolean;
  dateFormat?: string;
};

const classes = {
  fontSizeFix: {
    fontSize: 16
  }
};

const KeyDateItem: FC<KeyDateItemProps> = ({ date, title, isNewDate, dateFormat = 'MM/dd/yyyy hh:mm b, z' }) => (
  <Typography paragraph sx={classes.fontSizeFix}>
    <Box sx={{ fontWeight: 'bold', display: 'inline' }}>{`${title}: `}</Box>
    {date ? format(date, dateFormat) : ''}
    {isNewDate && (
      <Box sx={{ marginLeft: '10px', fontWeight: 'bold', display: 'inline' }}>
        NEW DATE
      </Box>
    )}
  </Typography>
);

export const KeyDates = ({ rfp }: props) => {
  if (rfp.rolling_cycle && !rfp.key_dates) {
    return (
      <Typography paragraph sx={classes.fontSizeFix}>
        Applications will be accepted on a rolling basis
      </Typography>
    );
  }

  if (!rfp.key_dates) {
    return (
      <Box>
        <KeyDateItem
          title={'Deadline to apply'}
          date={rfp.deadline_date ? new Date(rfp.deadline_date) : undefined}
          isNewDate={rfp.new_deadline_date}
        />
        <KeyDateItem
          title={'Decisions Expected to be Announced'}
          date={rfp.decision_date ? new Date(rfp.decision_date) : undefined}
          isNewDate={rfp.new_decision_date}
          dateFormat={"MM/dd/yyyy"}
        />
      </Box>
    );
  }

  const keyDates = rfp?.key_dates ? DOMPurify.sanitize(rfp?.key_dates) : '';
  return (
    <Grid
      sx={classes.fontSizeFix}
      dangerouslySetInnerHTML={{ __html: keyDates }}
    />
  );
};
