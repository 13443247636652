import { Box, Button, Divider, Modal, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { INotificationState } from './Notification';

interface NotificationRenderProps {
  notificationState: INotificationState;
  handleClose: (val: boolean, reason?: string) => void;
}

const NotificationRender: FC<NotificationRenderProps> = ({
  notificationState,
  handleClose
}) => {
  const {
    open,
    title,
    message,
    component,
    removeCancelBtn,
    customBtnText,
    customComponent,
    reason
  } = notificationState;

  const paper = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 600,
    backgroundColor: '#FFFFFF',
    boxShadow: 24,
    borderRadius: '16px',
    background: 'var(#FFFFFF, #FFF)',
    '@media (max-width: 900px)': {
      top: '8em',
      left: 'auto',
      margin: 'auto',
      transform: 'none'
    }
  };

  const border = {
    borderTop: '1px solid #979797',
    width: '100%'
  };

  const infoIconStyle = {
    color: '#fabc55',
    verticalAlign: 'sub' as 'sub'
  };

  return (
    <Modal
      open={open}
      onClose={() => handleClose(false, reason)}
      aria-describedby={'notification-title'}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box sx={paper}>
        <Grid container>
          <Grid item xs={12}>
            <Box sx={{ p: 4 }}>
              <Typography variant="h5">
                <InfoIcon style={infoIconStyle} />
                <span id="notification-title"> {title}</span>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider style={border} />
          </Grid>
          {message && (
            <Grid item xs={12}>
              <Box sx={{ px: 4, pt: 4 }}>
                {typeof message === 'string' ? (
                  <Typography component="p">{message}</Typography>
                ) : (
                  message
                )}
              </Box>
            </Grid>
          )}
          {component && (
            <Grid item xs={12}>
              <Box sx={{ px: 4, pt: 4, pb: 4 }}>
                <Typography component="p">{component}</Typography>
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box sx={{ px: 4, pt: 4, pb: 4 }}>
              <Typography component="p">{customComponent}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider style={border} />
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                p: 4,
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: 'row'
              }}
            >
              <Grid container spacing={1}>
                <Grid item>
                  {removeCancelBtn ? null : (
                    <Button
                      variant="outlined"
                      onClick={() => handleClose(false, reason)}
                    >
                      Cancel
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={() => handleClose(true, reason)}
                  >
                    {customBtnText ? customBtnText : 'Ok'}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default NotificationRender;
