import { ISurveyQuestion } from '../../../interfaces/ISurvey.interfaces';
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  styled
} from '@mui/material';
import { StyledList, StyledListItem } from '../Rfp/Rfp.styles';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { SurveyQuestionType } from '../../../enums/survey.enum';
import JFReadOnlyTextField from '../../../../common/components/JFReadOnlyTextField/JFReadOnlyTextField.component';

interface IApplicationSpecificQuestionsProps {
  surveyQuestions: ISurveyQuestion[];
}

const StyledOptionListItem = styled(ListItem)({
  paddingLeft: 'unset'
});

const StyledOptionList = styled(List)({
  padding: "unset"
})

const renderQuestionOptions = (parsedPayload: any) => {
  return Array.isArray(parsedPayload) && parsedPayload.length > 0 ? (
    <Grid>
      <StyledOptionList dense>
        {parsedPayload.map((option, idx) => {
          return option.value ? (
            <StyledOptionListItem key={option.value + idx}>
              <CheckBoxOutlineBlankIcon fontSize="small" />
              <ListItemText primary={option.value} />
            </StyledOptionListItem>
          ) : null;
        })}
      </StyledOptionList>
    </Grid>
  ) : null;
};

const renderQuestionListItem = (question: ISurveyQuestion) => {
  let parsedPayload = [];

  try {
    parsedPayload = question.payload && JSON.parse(question.payload);
  } catch (e) {
    console.error(e);
  }

  return question.description ? (
    <StyledListItem key={question.id}>
      <Grid>
        <JFReadOnlyTextField value={`${question.description}${question.is_required ? '*' : '' }`} />
      </Grid>
      {question.type === SurveyQuestionType.MULTIPLE ||
      question.type === SurveyQuestionType.SINGLE
        ? renderQuestionOptions(parsedPayload)
        : null}
    </StyledListItem>
  ) : null;
};

const ApplicationSpecificQuestions = (
  props: IApplicationSpecificQuestionsProps
) => {
  const { surveyQuestions } = props;

  return surveyQuestions.length > 0 ? (
    <Grid item sm={12}>
      <Typography variant="h3">Application Specific Questions</Typography>
      <StyledList>
        {surveyQuestions.map((question) => renderQuestionListItem(question))}
      </StyledList>
    </Grid>
  ) : null;
};

export default ApplicationSpecificQuestions;
